





















import Vue from '@/helpers/vue'
import BannerCarousel from '@/pages/home/BannerCarousel.vue'

export default Vue.extend({
  components: { BannerCarousel },
  data: () => ({
    sheetHeight: 200,
    infoTiles: [
      {
        title: 'Cash Donations',
        titleIcon: 'mdi-cash',
        titleIconColor: 'green',
        text: 'Monetary donations are always welcome at EMERGE. Click on the Donate button above! Your donation is tax deductible as we are a non-profit.'
      },
      {
        title: 'Corporate Partnerships',
        titleIcon: 'mdi-handshake',
        titleIconColor: 'indigo',
        text: 'If your company is interested in service projects with EMERGE, reach out to someone on the leadership team. Monetary, goods (i.e. backpacks, coats, school supplies), or volunteer time are welcome.'
      },
      {
        title: 'Serving On The Board',
        titleIcon: 'mdi-gavel',
        titleIconColor: 'blue',
        text: 'If you are interested in serving on EMERGE\'s Board of ten families, please reach out to someone on the leadership team. This years board is set, but we will keep a list of who is interested for next year.'
      },
      {
        title: 'Food Boxes',
        titleIcon: 'mdi-package-variant',
        titleIconColor: 'brown',
        text: 'Before each school break (Thanksgiving, Winter, and Spring), we need volunteers to assemble, pack, and deliver food boxes to families in need.'
      }
    ]
  }),
  methods: {
    adjustSheetHeight () {
      if (window.innerWidth > 1500) {
        this.sheetHeight = 200
      } else if (window.innerWidth < 1262 && window.innerWidth > 960) {
        this.sheetHeight = 150
      } else if (window.innerWidth <= 960) {
        this.sheetHeight = 220
      } else {
        this.sheetHeight = 250
      }
    }
  },
  mounted () {
    this.adjustSheetHeight()
  }
})
