











import Vue from 'vue'
import TopNav from '@/components/TopNav.vue'
import DonationDialog from '@/components/DonationDialog.vue'
import MembershipDialog from '@/components/MembershipDialog.vue'

export default Vue.extend({
  name: 'App',
  components: { TopNav, DonationDialog, MembershipDialog },
  data: () => ({
    //
  })
})
