






























































import Vue from '@/helpers/vue'
import StripePayment from '@/components/StripePayment.vue'

const initialDonationAmount = process.env.VUE_APP_INITIAL_DONATION_AMOUNT || '25'

export default Vue.extend({
  components: { StripePayment },
  data: () => ({
    message: '',
    stripeFormReady: false,
    stripeFormComplete: false,
    stripeRedirectUrl: window.location.origin + '/donated',
    showDialog: false,
    desiredDonationAmount: initialDonationAmount,
    donationAmount: '',
    sendingDonation: false,
    reset: 0,
    processPayment: 0
  }),
  computed: {
    showDonateDialog (): number {
      return this.$store.state.showDonateDialog
    },
    donationAmountValid (): boolean {
      return !!this.desiredDonationAmount.match(/^[1-9]{1}\d*(\.\d{2})?$/)
    },
    stripeFormLoading (): boolean {
      return !this.stripeFormReady && !!this.donationAmount && this.desiredDonationAmount === this.donationAmount
    },
    donationMetaData () {
      if (this.message) {
        return {
          message: this.message
        }
      }
      return undefined
    }
  },
  watch: {
    showDonateDialog (val: number, oldVal: number) {
      if (val > 0 && val !== oldVal && !this.showDialog) {
        this.showDialog = true
      }
    }
  },
  methods: {
    stripeReadyStateChange (ready: boolean) {
      this.stripeFormReady = ready
    },
    stripeFormCompleteStateChange (complete: boolean) {
      this.stripeFormComplete = complete
    },
    stripeProcessingPaymentStateChange (processing: boolean) {
      this.sendingDonation = processing
    },
    updateDonationAmount () {
      this.donationAmount = ''
      this.reset = (new Date()).valueOf()
    },
    async processDonation () {
      this.processPayment = (new Date()).valueOf()
    },
    async setDonationAmount () {
      this.donationAmount = this.desiredDonationAmount
    },
    cancel () {
      this.updateDonationAmount()
      this.showDialog = false
    }
  }
})
