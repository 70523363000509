























import Vue from '@/helpers/vue'
import isEmail from 'validator/lib/isEmail'
import isUrl from 'validator/lib/isURL'
import { loadStripe, Stripe, Appearance, StripePaymentElement, StripeElements, StripePaymentElementChangeEvent } from '@stripe/stripe-js'

let stripe: Stripe

export default Vue.extend({
  props: {
    chargeAmount: {
      type: String,
      validator: (val: string) => !val || !!val.match(/^[1-9]{1}\d*(\.\d{2})?$/)
    },
    chargeDescription: {
      type: String,
      required: true,
      validator: (val: string) => !!val
    },
    receiptEmail: {
      required: false
    },
    metaData: {
      type: Object
    },
    redirectUrl: {
      type: String,
      required: true,
      validator: (val: string) => {
        return process.env.NODE_ENV === 'development' ? true : isUrl(val)
      }
    },
    processPayment: {
      type: Number
    },
    reset: {
      type: Number
    },
    showChargeAmount: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    elements: null as StripeElements|null,
    paymentElement: null as StripePaymentElement|null,
    stripeError: '',
    initializing: false,
    formReady: false,
    formCompleted: false,
    processingPayment: false,
    email: ''
  }),
  watch: {
    chargeAmount: {
      immediate: true,
      handler (amount: string) {
        if (amount) {
          if (this.paymentElement) {
            this.clearForm()
          }
          this.initializeStripeForm(amount)
        }
      }
    },
    receiptEmail: {
      immediate: true,
      handler (email: string) {
        if (typeof email === 'string' && isEmail(email)) {
          this.email = email
          return
        }
        this.email = ''
      }
    },
    processPayment (val: number) {
      if (val) {
        this.processPaymentTransaction()
      }
    },
    reset (val: number) {
      if (val) {
        this.clearForm()
      }
    },
    formReady (val: boolean) {
      this.$emit('form-ready', val)
    },
    formCompleted (val: boolean) {
      this.$emit('form-completed', val)
    },
    processingPayment (val: boolean) {
      this.$emit('processing-payment', val)
    }
  },
  methods: {
    async initializeStripeForm (amount: string) {
      this.initializing = true
      const clientSecret = await this.fetchPaymentIntentSecret(amount)

      const appearance: Appearance = {
        theme: 'stripe'
      }

      this.elements = stripe.elements({ appearance, clientSecret })
      this.paymentElement = this.elements.create('payment')
      this.paymentElement.on('change', (event: StripePaymentElementChangeEvent) => {
        if (event.complete) {
          this.formCompleted = true
          return
        }
        this.formCompleted = false
      })
      this.paymentElement.on('ready', () => {
        this.formReady = true
        this.initializing = false
      })
      this.paymentElement.mount('#payment-element')
    },
    clearForm () {
      if (this.paymentElement) {
        this.paymentElement.destroy()
      }
      this.elements = null
      this.paymentElement = null
      this.stripeError = ''
      this.formReady = false
      this.formCompleted = false
      this.initializing = false
      this.$emit('form-ready', false)
      this.$emit('form-completed', false)
      this.$emit('processing-payment', false)
    },
    async processPaymentTransaction () {
      if (!this.elements) {
        return
      }
      this.processingPayment = true
      const { error } = await stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: this.redirectUrl,
          receipt_email: isEmail(this.email) ? this.email : undefined
        }
      })

      // Stripe should redirect before this code is reached if successful
      this.processingPayment = false
      this.stripeError = error.message || 'An unexpected error occured'
    },
    async fetchPaymentIntentSecret (amount: string): Promise<string> {
      const response = await fetch('/.netlify/functions/stripe-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount: parseFloat(amount),
          description: this.chargeDescription,
          // attach whatever here as an object
          metaData: this.metaData || null
        })
      })

      const { clientSecret } = await response.json()

      return clientSecret
    },
    getStripeKey (): string {
      const localDev: boolean = process.env.NODE_ENV === 'development'
      const stripeEnabled: boolean = process.env.VUE_APP_STRIPE_ENABLED === 'true'
      if (!localDev && stripeEnabled) {
        return process.env.VUE_APP_STRIPE_PUBLIC_KEY
      }
      return process.env.VUE_APP_STRIPE_TEST_PUBLIC_KEY
    }
  },
  async mounted () {
    const s = await loadStripe(this.getStripeKey())
    if (s) {
      stripe = s
    }
  }
})
