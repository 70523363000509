


























































































































import Vue from '@/helpers/vue'
import StripePayment from '@/components/StripePayment.vue'
import isEmail from 'validator/lib/isEmail'

export default Vue.extend({
  name: 'MembershipDialog',
  components: {
    StripePayment
  },
  data: () => ({
    showDialog: false,
    paymentPage: false,
    firstName: '',
    lastName: '',
    occupation: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    submitting: false,
    submitError: false,
    showSuccessMessage: false,
    membershipFee: (process.env.VUE_APP_MEMBERSHIP_FEE || '100') as string,
    stripeFormReady: false,
    stripeFormComplete: false,
    stripeRedirectUrl: window.location.origin + '/joined',
    sendingDonation: false,
    processPayment: 0
  }),
  computed: {
    showMembershipDialog (): number {
      return this.$store.state.showMembershipDialog
    },
    memberFormValid (): boolean {
      return !!(
        this.firstName &&
        this.lastName &&
        isEmail(this.email) &&
        this.phone &&
        this.address &&
        this.city &&
        this.state &&
        this.zip &&
        this.zip.match(/^\d{5}/)
      )
    },
    paymentMetaData () {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        occupation: this.occupation,
        email: this.email,
        phone: this.phone,
        address: this.address,
        city: this.city,
        state: this.state,
        zip: this.zip
      }
    }
  },
  watch: {
    showMembershipDialog (val: number, oldVal: number) {
      if (val > 0 && val !== oldVal && !this.showDialog) {
        this.showDialog = true
      }
    }
  },
  methods: {
    isEmail,
    stripeReadyStateChange (ready: boolean) {
      this.stripeFormReady = ready
    },
    stripeFormCompleteStateChange (complete: boolean) {
      this.stripeFormComplete = complete
    },
    stripeProcessingPaymentStateChange (processing: boolean) {
      this.sendingDonation = processing
    },
    async processMembershipFee () {
      this.processPayment = (new Date()).valueOf()
    }
  }
})
