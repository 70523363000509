











import Vue from '@/helpers/vue'

interface BannerImage {
  src: string
  portrait: boolean
  width?: number
}
export default Vue.extend({
  data: () => ({
    images: [
      {
        src: 'https://emerge-public.s3.amazonaws.com/website/media/home-carousel/image0.jpeg',
        portrait: false
      },
      {
        src: 'https://emerge-public.s3.amazonaws.com/website/media/home-carousel/image1.jpeg',
        portrait: true
      },
      {
        src: 'https://emerge-public.s3.amazonaws.com/website/media/home-carousel/image2.jpeg',
        portrait: true
      },
      {
        src: 'https://emerge-public.s3.amazonaws.com/website/media/home-carousel/image3.jpeg',
        portrait: true
      },
      {
        src: 'https://emerge-public.s3.amazonaws.com/website/media/home-carousel/image4.jpeg',
        portrait: true
      },
      {
        src: 'https://emerge-public.s3.amazonaws.com/website/media/home-carousel/image5.jpeg',
        portrait: false
      },
      {
        src: 'https://emerge-public.s3.amazonaws.com/website/media/home-carousel/image6.jpeg',
        portrait: true,
        width: 588
      },
      {
        src: 'https://emerge-public.s3.amazonaws.com/website/media/home-carousel/image7.jpeg',
        portrait: false
      },
      {
        src: 'https://emerge-public.s3.amazonaws.com/website/media/home-carousel/image8.jpeg',
        portrait: false
      }
    ] as BannerImage[]
  }),
  methods: {
    getBackgroundImageUrl (image: BannerImage) {
      return {
        backgroundImage: `url("${image.src}")`
      }
    }
  }
})
