import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export interface EmergeState {
  showDonateDialog: number;
  showMembershipDialog: number;
}

export default new Vuex.Store<EmergeState>({
  state: {
    showDonateDialog: 0,
    showMembershipDialog: 0
  },
  mutations: {
    showDonateDialog (state) {
      state.showDonateDialog = (new Date()).valueOf()
    },
    showMembershipDialog (state) {
      state.showMembershipDialog = (new Date()).valueOf()
    }
  },
  actions: {
  },
  modules: {
  }
})
