import Vue, { VueConstructor } from 'vue'
import { Store } from 'vuex'
import { EmergeState } from '@/store'

abstract class StrongVueClass extends Vue {
  public $store!: Store<EmergeState>;
}
const StrongVue = Vue as VueConstructor<StrongVueClass>

export default StrongVue
