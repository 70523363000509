















import Vue from '@/helpers/vue'

export default Vue.extend({
  name: 'Joined',
  methods: {
    async submitMembershipInfo () {
      const paymentIntentId = this.$route.query.payment_intent
      if (!paymentIntentId) {
        return
      }
      await fetch('/.netlify/functions/send-membership-form-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ paymentIntentId })
      })
    }
  },
  async mounted () {
    await this.submitMembershipInfo()
  }
})
